<template>
  <v-main >
    <router-view />

    <!--
    <dashboard-core-footer />
    -->
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
<style lang="sass" scoped>

</style>
